<template>
  <div class="d-flex fill-height flex-column">
    <page-bar :to="{ name: 'home' }" title="Harta sesizari" class="orange lighten-5"/>
    <v-container class="pa-0 fill-height" fluid>
      <citizen-reports-map/>
    </v-container>
  </div>
</template>
<script>
import CitizenReportsMap from '@/components/CitizenReportsMap.vue'
export default {
  components: {
    CitizenReportsMap
  }
}
</script>
